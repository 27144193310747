export const nodeENV = process.env.NEXT_PUBLIC_NODE_ENV || 'development'

const mapServer: Record<string, string> = {
  production: `${process.env.NEXT_PUBLIC_API_PROD_URL}/${process.env.NEXT_PUBLIC_API_PROD_VERSION}`,
  stage: `${process.env.NEXT_PUBLIC_API_STAGE_URL}/${process.env.NEXT_PUBLIC_API_STAGE_VERSION}`,
  development: `${process.env.NEXT_PUBLIC_API_DEV_URL}/${process.env.NEXT_PUBLIC_API_DEV_VERSION}`,
  local: `${process.env.NEXT_PUBLIC_API_LOCAL_URL}/${process.env.NEXT_PUBLIC_API_LOCAL_VERSION}`
}

const mapCheckbookServer: Record<string, string> = {
  production: `${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_PROD_URL}/${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_PROD_VERSION}`,
  stage: `${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_URL}/${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_VERSION}`,
  development: `${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_URL}/${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_VERSION}`,
  local: `${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_URL}/${process.env.NEXT_PUBLIC_API_DIGITAL_CHECKBOOK_DEV_VERSION}`
}

export const server = mapServer[nodeENV]

export const checkbook_server = mapCheckbookServer[nodeENV]

export const baseUrl = process.env.NEXT_PUBLIC_MAIN_URL

const CONFIGS = Object.freeze({
  env: nodeENV,
  server: server,
  appUrl: baseUrl,
  apiServer: process.env.NEXT_PUBLIC_API_SERVER,
  appName: process.env.NEXT_PUBLIC_APP_NAME ?? 'PayToMe',
  isCsrfRequired: !!Number(process.env.NEXT_PUBLIC_IS_CSRF_REQUIRED)
})

export default CONFIGS
